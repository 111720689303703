
import api from "@/api";
import {
  KioskSessionModel,
  OperationMode,
  OperationModeConstantModel,
} from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import UserModule from "@/store/userModule";
import { getModule } from "vuex-module-decorators";
import userRoleConstant from "@/Constants/userRoleConstant";

const userModule = getModule(UserModule);

@Component
export default class KioskSessionCard extends Vue {
  @Prop(Object) private kioskSession!: KioskSessionModel;
  @Prop(Array) private operationModes!: Array<OperationModeConstantModel>;

  // private get leftKeg() {
  //   return this.kioskSession.kioskSessionStatuses?.length
  //     ? this.kioskSession.kioskSessionStatuses[0].leftKeg
  //     : 0;
  // }

  // private get rightKeg() {
  //   return this.kioskSession.kioskSessionStatuses?.length
  //     ? this.kioskSession.kioskSessionStatuses[0].rightKeg
  //     : 0;
  // }

  get adminRoles() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.onSiteUser
    );
  }

  private async setOperationMode(id: string, operationMode: OperationMode) {
    await api.KioskSessionService.apiKiosksessionModeKioskSessionIdPost(
      id,
      operationMode
    );
    this.$emit("refresh");
  }

  private buttonClicked(dialogName: string, id: string) {
    this.$emit("button-clicked", dialogName, id);
  }

  private async removeKioskFromSession(KioskSessionId: string) {
    await api.KioskSessionService.apiKiosksessionKioskSessionIdDelete(
      KioskSessionId
    );
    this.$emit("refresh");
  }
}
